
import { mapGetters } from "vuex"

export default {
  computed: {
    ...mapGetters(["currentUser"]),
  },
  methods: {
    getFeature(name) {
      let feature = this.currentUser.beta_features.find(feature => feature.name == name)
      feature.description = this.$t("user.edit.features.description." + name)
      feature.title = this.$t("user.edit.features.title." + name)
      return feature
    },
  },
}
